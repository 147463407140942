import { createApi } from '@reduxjs/toolkit/query/react';
import axiosInstance from '../../axiosConfig';

const baseQuery = async ({ url, method, body, params }) => {
  try {
    const result = await axiosInstance({
      url,
      method,
      data: body,
      params,
    });
    return { data: result };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const overviewApi = createApi({
  reducerPath: 'overviewApi',
  baseQuery,
  endpoints: (builder) => ({
    getOverviewData: builder.query({
      query: ({ productId, bodyParams }) => {
        return {
          url: `${productId}/overview-data`,
          method: 'POST',
          body: bodyParams,
        };
      },
    }),
  }),
});

export const { useGetOverviewDataQuery } = overviewApi;

window.addEventListener('storage', (event) => {
  if (event.key === 'selectedProduct') {
    overviewApi.endpoints.getOverviewData.initiate(); 
  }
});