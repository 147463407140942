import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('userToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem("selectedProducts")
        localStorage.removeItem("userToken")
        window.location.replace('/signin');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
