import { createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosInstance from '../../axiosConfig';

const baseQuery = async ({ url, method, body, params }) => {
  try {
    const result = await axiosInstance({
      url,
      method,
      data: body,
      params,
    });
    return { data: result };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery,
  endpoints: (build) => ({
    getProducts: build.query({
      query: ({search_term, page}) => ({
        url: 'products',
        method: 'GET',
        params: { search_term , page},
      }),
    }),
  }),
});

const initialState = {
  products: [],
  isLoading: false,
  error: null,
  selectedProduct: JSON.parse(localStorage.getItem('selectedProduct')) || null,
};

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
      localStorage.setItem('selectedProduct', JSON.stringify(action.payload));
    },
  },
});

export const {  useGetProductsQuery } = productApi;

export const { setProducts, setLoading, setError, setSelectedProduct } = productSlice.actions;

export const selectSelectedProduct = (state) => state.products.selectedProduct;

export const selectProductId = (state) => state.products.selectedProduct?.id || null;
export const getProductIdFromLocalStorage = () => {
  const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
  return selectedProduct ? selectedProduct.id : null;
};

export default productSlice.reducer;

window.addEventListener('storage', (event) => {
  if (event.key === 'userToken') {
    productApi.endpoints.getProducts.initiate(); 
  }
});