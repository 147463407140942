import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Main from "./component/layout/main";
import SignIn from "./component/pages/SignIn"
import ProtectedRoute from "./utils/protectedRoute"
import store from "./redux/store";
import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";
import "./App.css";
import "./responsive.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

function App() {
  const [date,setDate] = useState([moment().subtract(1, 'month'), moment()])
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Switch>
            <Route path="/signin" component={SignIn} />
            <ProtectedRoute path="/:id" component={Main} date = {date} setDate = {setDate}/>
            <Redirect
              to={{
                pathname: "/signin",
              }}
            />
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
