import { combineReducers } from 'redux';
import productReducer,{ productApi } from './features/product/productService';
import authReducer from './features/auth/authService';
import customerDataReducer, { customerDataApi } from './features/customer-data/customerDataService';
import  { overviewApi } from './features/overview/overviewService';
import resetReducer from './features/resetPassword/resetService';
import { conversationalApi } from './features/conversational-analysis/conversationalService';

const rootReducer = combineReducers({
  auth: authReducer,
  customerData: customerDataReducer,
  reset: resetReducer,
  product: productReducer,
  [overviewApi.reducerPath]: overviewApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [customerDataApi.reducerPath]: customerDataApi.reducer,
  [overviewApi.reducerPath]: overviewApi.reducer,
  [conversationalApi.reducerPath] : conversationalApi.reducer

});

export default rootReducer;
