import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import NavLogo from "./navLogo";
import NavContent from "./navContent";
import { Avatar } from "antd";

class Navigation extends Component {
  render() {
    const localData = JSON.parse(localStorage.getItem("selectedProduct"));

    let navContent = (
      <React.Fragment>
        <NavLogo
          collapseMenu={this.props.collapseMenu}
          windowWidth={this.props.windowWidth}
          onToggleNavigation={this.props.onToggleNavigation}
        />
        <div className="aside-footer">
          <div className="footer-box">
            <Avatar src={localData?.image} width= {50} className="productAvatar"></Avatar>
            <h5 className="h5">{localData?.name}</h5>
            <Link type="primary" to="/choose-product">
              <div className="allProductSidebartext"> 
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.1572 5.86496L9.38217 9.20996C9.14967 9.34496 8.85717 9.34496 8.61717 9.20996L2.84217 5.86496C2.42967 5.62496 2.32467 5.06246 2.63967 4.70996C2.85717 4.46246 3.10467 4.25996 3.36717 4.11746L7.43217 1.86746C8.30217 1.37996 9.71217 1.37996 10.5822 1.86746L14.6472 4.11746C14.9097 4.25996 15.1572 4.46996 15.3747 4.70996C15.6747 5.06246 15.5697 5.62496 15.1572 5.86496Z"
                    fill="#222222"
                  />
                  <path
                    d="M8.57285 10.605V15.72C8.57285 16.29 7.99535 16.665 7.48535 16.4175C5.94035 15.66 3.33785 14.2425 3.33785 14.2425C2.42285 13.725 1.67285 12.42 1.67285 11.3475V7.47753C1.67285 6.88503 2.29535 6.51003 2.80535 6.80253L8.19785 9.93003C8.42285 10.0725 8.57285 10.3275 8.57285 10.605Z"
                    fill="#222222"
                  />
                  <path
                    d="M9.42773 10.605V15.72C9.42773 16.29 10.0052 16.665 10.5152 16.4175C12.0602 15.66 14.6627 14.2425 14.6627 14.2425C15.5777 13.725 16.3277 12.42 16.3277 11.3475V7.47753C16.3277 6.88503 15.7052 6.51003 15.1952 6.80253L9.80273 9.93003C9.57773 10.0725 9.42773 10.3275 9.42773 10.605Z"
                    fill="#222222"
                  />
                </svg>
                <p>View All Chatbots</p>
              </div>
            </Link>
          </div>
        </div>
        <hr />
        <NavContent
          navigation={this.props.menuItems}
          handleSidebarColor={this.props.handleSidebarColor}
          currentSelected={this.props.currentSelected}
        />
      </React.Fragment>
    );
    return <>{navContent}</>;
  }
}

export default withRouter(Navigation);
