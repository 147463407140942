import React, { Component } from "react";
import { Layout, Button, Row, Col, Form, Input, Card, Alert } from "antd";
import wozImage from "../images/WOZLogo.svg";
import wozMobile from "../images/WOZ.svg";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { userResetPassword } from "../../redux/features/resetPassword/resetService";
import { connect } from "react-redux";
import axios from "axios";
import darkLogo from "../images/WOZLogoDark.svg";
import {Link} from "react-router-dom"
import Loader from "../loader";

const { Content } = Layout;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      password: "",
      password_confirmation: "",
      errorMessage: null,
      loading: false,
      tokenValid: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    this.setState({ token });
    this.validateToken(token);
  }

  validateToken = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/reset-token-verification`,
        { token }
      );
      const { valid } = response.data;
      this.setState({ tokenValid: valid });
    } catch (error) {
      console.error("Error validating token:", error);
      this.setState({ tokenValid: false });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFinish = async () => {
    const { token, password, password_confirmation } = this.state;
    const { userResetPassword } = this.props;

    this.setState({ loading: true, errorMessage: null });
    try {
      await 
        userResetPassword({
          token,
          password,
          password_confirmation,
        }).unwrap();
      this.setState({ loading: false , errorMessage: null  });
      this.props.history.push("/signin");
    } catch (err) {
      this.setState({
        loading: false,
        errorMessage: err.message || "An error occurred",
      });
    }
  };

  onFinishFailed = () => {
    this.setState({
      errorMessage: "Please check your inputs and try again.",
      loading: false,
    });
  };

  render() {
    const {
      password,
      password_confirmation,
      loading,
      errorMessage,
      tokenValid,
    } = this.state;

    if (tokenValid === null) {
      return (
        <div className="loader-not-token">
        <Loader />
        </div>
      );
    }

    if (tokenValid === false) {
      return (
        <Layout className="layout-default layout-signin">
          <Content className="signin reset">
            <Row gutter={[24, 24]} style={{width: "100%"}} justify={'center'} align={'center'} >
              <Col md={12}>
                <Card bordered={false} className="p-4 rounded tokenNotFound">
                  <img
                    src={darkLogo}
                    alt="login woz"
                    style={{ width: "125px", height: "29px" }}
                    className="loginWozImage mb-5"
                  />
                  <Alert
                    message="Invalid token. Please request a new password reset."
                    type="error"
                    style={{  }}
                    className="mb-5 resetAlert"
                  />
                  <Link to="/forgot-password">
                    Navigate to Forgot Password ...
                  </Link>
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      );
    }

    return (
      <>
        <Layout className="layout-default layout-signin">
          <Content className="signin signinHeight">
            <Row
              gutter={[24, 24]}
              justify="center"
              align="center"
              className="signinRow"
            >
              <Col className="loginSideContainer1">
                <div className="sideContainer">
                  <img
                    src={wozMobile}
                    alt="login woz"
                    style={{ width: "65px", height: "37px" }}
                    className="loginWozImagemb"
                  />
                  <h1 className="h1">Log in to Your Account</h1>
                  <p>
                    Explore how WOZ delivers personalized, interactive
                    experiences that captivate, engage and retain your
                    customers.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12}>
                <Card bordered={false} className="signinCard forgotCard">
                  <div className="sininDetailsContainer">
                    <h3 className="mb-5 h3">Reset Password</h3>
                    <Form
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      layout="vertical"
                      className="row-col"
                    >
                      <Form.Item
                        className="username"
                        label="New Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Password"
                        name="password"
                          onChange={this.handleInputChange}
                          value={password}
                          iconRender={(visible) =>
                            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        className="username"
                        label="Confirm Password"
                        name="password_confirmation"
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two passwords do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder="Confirm Password"
                        name="password_confirmation"
                          onChange={this.handleInputChange}
                          value={password_confirmation}
                          iconRender={(visible) =>
                            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            width: "100%",
                            backgroundColor: "#190041",
                            border: "none",
                          }}
                          block
                          disabled={loading}
                          icon={loading ? <LoadingOutlined /> : null}
                        >
                          {loading ? "Submitting..." : "Submit"}
                        </Button>
                      </Form.Item>
                      {errorMessage && (
                        <Alert message={errorMessage} type="error" showIcon className="signin-alerts mb-4" />
                      )}
                    </Form>
                  </div>
                  <footer className="footer-copyright">
                    <p className="text-mute">Copyright 2024 WOZ. All Rights Reserved.</p>
                  </footer>
                </Card>
              </Col>
              <Col lg={8} xl={8} xxl={8} className="loginSideContainer">
                <div className="sideContainer">
                  <img
                    src={wozImage}
                    alt="login woz"
                    style={{ width: "125px", height: "29px" }}
                    className="loginWozImage"
                  />
                  <h1 className="h1">Log in to Your Account</h1>
                  <p>
                    Explore how WOZ delivers personalized, interactive
                    experiences that captivate, engage and retain your
                    customers.
                  </p>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.forgot ? state.forgot.loading : false,
  errorMessage: state.forgot ? state.forgot.error : null,
});

export default connect(mapStateToProps, { userResetPassword })(ResetPassword);
