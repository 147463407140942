import { createApi } from '@reduxjs/toolkit/query/react';
import axiosInstance from '../../axiosConfig';

const baseQuery = async ({ url, method, body, params }) => {
  try {
    const result = await axiosInstance({
      url,
      method,
      data: body,
      params,
    });
    return { data: result };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};


export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery,
  endpoints: (build) => ({
    getChats: build.query({
      query: ({ search_term, page, productId,customer_id,filters }) => {
        return {
        url: `${productId}/conversations`,
        method: 'POST',
        params: { search_term, page,customer_id},
        body: {filters: filters}
      }},
    }),
    getChatData: build.query({
      query: ({ chatID,productId }) => {
        return{
        url: `${productId}/conversations/${chatID}/chat-logs`,
        method: 'GET',
      }},
    }),
  }),
});

export const { useGetChatsQuery, useGetChatDataQuery } = chatApi;

window.addEventListener('storage', (event) => {
  if (event.key === 'selectedProduct') {
    chatApi.endpoints.getChats.initiate(); 
    chatApi.endpoints.getChatData.initiate(); 
  }
});