/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Card } from "antd";
import wozImage from "../images/WOZLogo.svg";
import wozMobile from "../images/WOZ.svg";

const { Content } = Layout;

export default class ForgotPassword extends Component {

  render() {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");

    return (
      <Layout className="layout-default layout-signin">
        <Content className="signin signinHeight">
          <Row
            gutter={[24, 24]}
            justify="center"
            align="center"
            className="signinRow"
          >
            <Col className="loginSideContainer1">
              <div className="sideContainer">
                <img
                  src={wozMobile}
                  alt="login woz"
                  style={{ Width: "65px", Height: "37" }}
                  className="loginWozImagemb"
                />
                <h1 className="h1">Log in to Your Account</h1>
                <p>
                  Explore how WOZ delivers personalized, interactive experiences
                  that captivate, engage and retain your customers.
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12}>
              <Card bordered={false} className="signinCard forgotCard">
                <div className="sininDetailsContainer linkContainer">
                  <h3 className="mb-5 h3">Forgot Password</h3>
                  <p className="emailSent">
                    An email has been sent to{" "}
                    <a href={`mailto:${email}`}>{email}.</a> <br /> Please check
                    your inbox
                  </p>
                  <Link
                    to="/forgot-password"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    Change email
                  </Link>
                </div>
                <footer className="footer-copyright">
                    <p className="text-mute">Copyright 2024 WOZ. All Rights Reserved.</p>
                  </footer>
              </Card>
            </Col>
            <Col lg={8} xl={8} xxl={8} className="loginSideContainer">
              <div className="sideContainer">
                <img
                  src={wozImage}
                  alt="login woz"
                  style={{ Width: "125px", Height: "29px" }}
                  className="loginWozImage"
                />
                <h1 className="h1">Log in to Your Account</h1>
                <p>
                  Explore how WOZ delivers personalized, interactive experiences
                  that captivate, engage and retain your customers.
                </p>
              </div>
            </Col>
          <footer className="copyright mobile-copyright-footer">
            <p>© WOZ.AI 2024. All rights reserved.</p>
          </footer>
          </Row>
        </Content>
      </Layout>
    );
  }
}
