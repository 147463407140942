import React, { Component } from "react";
import HeaderTop from "./HeaderTop";
import { Layout, Drawer } from "antd";
import Navigation from "./navigation";
import { withRouter, Route, Switch } from "react-router-dom";
import { routes, extraRoutes } from "../../utils/routes";
import SvgIcon from "../dynamicIcons/dash";

const { Header, Content } = Layout;
const { Sider } = Layout;
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      placement: "left",
      currentComponent: "",
      sidebarColor: "#1890ff",
      sidenavType: "transparent",
      fixed: false,
      name: "",
      subName: "",
      breadcrumb: "",
      collapsed: false,
      activateMouseHover: false,
      id: 1,
      menuItems: [],
    };
  }

  componentDidMount() {
    if (this.props?.location?.pathname) {
      let page = this.props.location.pathname.split("/");
      page = page[page.length - 1];
      this.setState({ currentComponent: page });
    }
    this.updateIdFromLocalStorage();
    this.updateMenuItems();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname) {
        let page = this.props.location.pathname.split("/");
        page = page[page.length - 1];
        this.setState({ currentComponent: page });
      }
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id }, () => {
        this.updateMenuItems();
      });
    }
  }

  OpenDrawer = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  handleSidenavType = (type) => {
    this.setState({ sidenavType: type });
  };

  handleSidebarColor = (color) => {
    this.setState({ sidebarColor: color });
  };

  handleFixedNavbar = (type) => {
    this.setState({ fixed: type });
  };

  toggleCollasped = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  updateIdFromLocalStorage = () => {
    const localData = JSON.parse(localStorage.getItem("selectedProduct"));
    if (localData) {
      this.setState({ id: localData.id });
    }
  };

  updateMenuItems = () => {
    const { id } = this.state;
    const updatedMenuItems = [
      {
        id: "overview",
        title: <span className={`label`}>Overview</span>,
        type: "item",
        icon: (isSelected, color) => (
          <SvgIcon
            name="home"
            style={{
              background: isSelected ? color : "",
            }}
            isSelected={isSelected}
          />
        ),
        url: `/${id}/dashboard`,
      },
      {
        id: "page",
        title: "",
        type: "group",
        children: [
          {
            id: "analysis",
            title: <span className="label">Analysis</span>,
            type: "collapse",
            icon: (isSelected, color) => (
              <SvgIcon
                name="messages"
                style={{
                  background: isSelected ? color : "",
                }}
                isSelected={isSelected}
              />
            ),
            children: [
              {
                id: "conversation-analysis",
                title: "Conversation Analysis",
                type: "item",
                url: `/${id}/analysis/conversation-analysis`,
              },
              {
                id: "chat-history",
                title: "Chat History",
                type: "item",
                url: `/${id}/analysis/chat-history`,
              },
              {
                id: "common-queries",
                title: 'Common Queries',
                type: "item",
                url: `/${id}/analysis/common-queries`,
              },
            ],
          },
        ],
      },
      {
        id: "customer-data",
        title: <span className={`label`}>Customer Data</span>,
        type: "item",
        icon: (isSelected, color) => (
          <SvgIcon
            name="user"
            style={{
              background: isSelected ? color : "",
            }}
            isSelected={isSelected}
          />
        ),
        url: `/${id}/customer-data`,
      },
      {
        id: "settings",
        title: <span className={`label`}>Settings</span>,
        type: "item",
        icon: (isSelected, color) => (
          <SvgIcon
            name="setting"
            style={{
              background: isSelected ? color : "",
            }}
            isSelected={isSelected}
          />
        ),
        url: `/${id}/settings`,
      },
      {
        id: "logout",
        title: <span className={`label`}>Logout</span>,
        type: "item",
        icon: (isSelected, color) => (
          <SvgIcon
            name="logout"
            style={{
              background: isSelected ? color : "",
            }}
            isSelected={isSelected}
          />
        ),
        url: "/signin",
      },
    ];
    this.setState({ menuItems: updatedMenuItems });
  };

  render() {
    const { date, setDate } = this.props;

    const validComponents = [
      "signin",
      "forgot-password",
      "recovery",
      "choose-product",
      "reset-password"
    ];

    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} key={index} date={this.props.date}
            setDate={this.props.setDate} />}
        />
      ) : null;
    });

    const extraRoute = extraRoutes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} key={index} />}
        />
      ) : null;
    });

    return (
      <>
        {validComponents.includes(this.state.currentComponent) ? (
          <Switch>{extraRoute}</Switch>
        ) : (
          <>
          <Layout
            className={`layout-dashboard ant-layout ant-layout-has-sider has-sidebar`}
          >
            <Drawer
              title={false}
              placement={this.state.placement === "right" ? "left" : "right"}
              closable={false}
              onClose={() => this.setState({ visible: false })}
              open={this.state.visible}
              key={this.state.placement === "right" ? "left" : "right"}
              width={300}
              className={`drawer-sidebar ${this.state.currentComponent === "rtl"
                  ? "drawer-sidebar-rtl"
                  : ""
                } `}
            >
              <Layout
                className={`layout-dashboard ${this.state.currentComponent === "rtl"
                    ? "layout-dashboard-rtl"
                    : ""
                  }`}
              >
                <Sider
                  trigger={null}
                  width={300}
                  theme="light"
                  className={`sider-primary ant-layout-sider-primary ${this.state.sidenavType === "#fff" ? "active-route" : ""
                    }`}
                  style={{ background: this.state.sidenavType }}
                >
                  <Navigation
                    currentSelected={this.state.currentComponent}
                    handleSidebarColor={this.handleSidebarColor}
                    menuItems={this.state.menuItems}
                  />
                </Sider>
              </Layout>
            </Drawer>
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${this.state.sidenavType === "#fff" ? "active-route" : ""
                }`}
              style={{ background: this.state.sidenavType }}
            >
              <Navigation
                currentSelected={this.state.currentComponent}
                handleSidebarColor={this.handleSidebarColor}
                menuItems={this.state.menuItems}
              />
            </Sider>
            <Layout id="temp__div">
              <Header className="ant-header-fixed">
                <HeaderTop
                  date={date}
                  setDate={setDate}
                  onPress={this.OpenDrawer}
                  toggleCollasped={this.toggleCollasped}
                />
              </Header>
              <Content className="content-ant ">
                <Switch>{menu}</Switch>
              </Content>
            </Layout>

          </Layout>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Main);
