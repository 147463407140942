/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../../images/WOZLogoDark.svg";

const navLogo = (props) => {
  return (
    <>
      <div className="brand">
        <img src={logo} alt="woz logo" />
      </div>
    </>
  );
};

export default navLogo;
