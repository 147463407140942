import { configureStore } from '@reduxjs/toolkit';
import { customerDataApi } from './features/customer-data/customerDataService';
import { productApi } from './features/product/productService';
import rootReducer from "./rootReducer";
import { chatApi } from './features/chatHistory/chatService';
import { overviewApi } from './features/overview/overviewService';
import { conversationalApi } from './features/conversational-analysis/conversationalService';

const store = configureStore({
  reducer: {
    root: rootReducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerDataApi.reducerPath]: customerDataApi.reducer,
    [chatApi.reducerPath]:chatApi.reducer,
    [overviewApi.reducerPath] : overviewApi.reducer,
    [conversationalApi.reducerPath] : conversationalApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(productApi.middleware)
      .concat(customerDataApi.middleware)
      .concat(chatApi.middleware)
      .concat(overviewApi.middleware)
      .concat(conversationalApi.middleware)
});

export default store;
