import axiosInstance from '../../axiosConfig';

import { createApi } from '@reduxjs/toolkit/query/react';
const baseQuery = async ({ url, method, body, params }) => {
  try {
    const result = await axiosInstance({
      url,
      method,
      data: body,
      params,
    });
    return { data: result };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const conversationalApi = createApi({
  reducerPath: 'conversationalApi',
  baseQuery,
  endpoints: (builder) => ({
    getConversationalData: builder.query({
      query: ({ productId,bodyParams}) => {
        if (!productId) {
          throw new Error('No productId found in localStorage');
        }
        return {
          url: `${productId}/conversation-analysis-data`,
          method: 'POST',
          body: bodyParams,
        };
      },
    }),
  }),
});

export const { useGetConversationalDataQuery } = conversationalApi;

window.addEventListener('storage', (event) => {
  if (event.key === 'selectedProduct') {
    conversationalApi.endpoints.getConversationalData.initiate(); 
  }
});
