import face5 from "../images/history/profile.png";
import zappier from "../images/zappier.png";

import React, { Component } from "react";
import { Card, Col, Row, Table, Select, Button, Avatar } from "antd";
import {} from "@ant-design/icons";
import { Radio } from "antd";
import Title from "antd/lib/typography/Title";
import userSettingsData from "../../data/userSettingsData"

const { Option } = Select;

export default class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
    totalPage: 10,
    value: 0,
      isLinked: false, 
    };
  }

  handleTotalPageChange = (val) => {
    this.setState({ totalPage: val });
  };

  changeAccount = (e) => {
    this.setState((prevState) => ({
      isLinked: !prevState.isLinked,
    }));
  };

  render() {
    const onChange = (e) => {
      this.setState({ value: e.target.value });
    };

    const tabItem = [
      {
        id: 0,
        title: "Profile",
      },
    ];
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: {
          compare: (a, b) => a.email - b.email,
          multiple: 3,
        },
      },
      {
        title: "Role",
        dataIndex: "role",
        sorter: {
          compare: (a, b) => a.role - b.role,
          multiple: 2,
        },
      },
    ];

    
    const { isLinked, totalPage } = this.state;
    return (
      <>
        <div className="layout-content settingsPage">
          <div className="tabs-container">
            <Radio.Group
              onChange={onChange}
              value={this.state.value}
              className="custom-tabs"
            >
              {tabItem &&
                tabItem.map((item, index) => {
                  return (
                    <Radio
                      value={item.id}
                      className={
                        this.state.value === item.id ? "tab selected" : "tab"
                      }
                      key={index}
                    >
                      {item.title}
                    </Radio>
                  );
                })}
            </Radio.Group>
            <div className="tab-content">
              {this.state.value === 0 && (
                <div>
                  {" "}
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      xl={24}
                      lg={24}
                      xxl={24}
                      span={24}
                      className="zindex"
                    >
                      <Row gutter={[24, 0]}>
                        <Card className="mb-24 w-auto">
                          <Row gutter={[24, 0]} className="item-body">
                            <Avatar
                              src={face5}
                              alt="Face 5"
                              className="icon-box card-image"
                            />
                            <Col>
                              <h4 className="h4 mb-0">John Doe</h4>
                              <Col className="card-footer">
                                <span className="admin">
                                  Super Admin
                                </span>
                                <Button
                                  className="text-end"
                                  type="link"
                                >
                                  Change Password
                                </Button>
                              </Col>
                            </Col>
                          </Row>
                        </Card>
                      </Row>
                    </Col>
                  </Row>
                  <Card
                    bodyStyle={{ padding: "16px 0px 0px" }}
                    bordered={false}
                    className="header-solid mb-24"
                    title={[<Title level={4}> Users </Title>]}
                  >
                    <div className="px-25">
                      <Select
                        defaultValue={totalPage}
                        onChange={this.handleTotalPageChange}
                        size="middle"
                        style={{ width: "70px" }}
                      >
                        <Option value="5">5</Option>
                        <Option value="10">10</Option>
                        <Option value="15">15</Option>
                        <Option value="20">20</Option>
                        <Option value="25">25</Option>
                      </Select>

                      <label className="ml-10">entries per page</label>
                    </div>
                    <div className="ant-table-body">
                      <Table
                        className="mt-20 text-center"
                        columns={columns}
                        dataSource={userSettingsData}
                        tableLayout="auto"
                        onChange={onchange}
                        scroll={{ x: 500 }}
                        pagination={{
                          pageSize: totalPage,
                        }}
                      />
                    </div>
                  </Card>
                </div>
              )}
              {this.state.value === 1 && (
                <div>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      xl={24}
                      lg={24}
                      xxl={24}
                      span={24}
                      className="zindex"
                    >
                      <Row gutter={[24, 0]}>
                        <Card className="mb-24 w-auto">
                          <Row gutter={[24, 0]} className="item-body">
                            <Avatar
                              src={zappier}
                              alt="zappier"
                              className="icon-box card-image"
                            />
                            <Col>
                              <h4 className="h4 mb-1">Zappier</h4>
                              <Col className="card-footer">
                                <p
                                  className={`admin mb-0 ${
                                    isLinked ? " text-succes" : ""
                                  }`}
                                >
                                  {isLinked && (
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        verticalAlign: "middle",
                                        marginRight: "8px",
                                      }} // Styling for alignment
                                    >
                                      <path
                                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                        fill="#00AB55"
                                      />
                                    </svg>
                                  )}
                                  {isLinked
                                    ? "Account Linked"
                                    : "Account Not Linked"}
                                </p>
                                <Button
                                  className="text-end"
                                  type="link" // Changed to "button" as "link" is not a standard type, unless it's a custom implementation.
                                  onClick={this.changeAccount}
                                >
                                  {isLinked ? "Unlink Account" : "Link Account"}
                                </Button>
                              </Col>
                            </Col>
                          </Row>
                        </Card>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
