import React, { useState } from "react";
import { Input, Layout, Row, Col, Card, Button, Pagination } from "antd";
import { useHistory } from "react-router-dom";
import { searchIcon } from "../../data/homeData";
import wozLogo from "../images/WOZLogoDark.svg";
import { useGetProductsQuery, setSelectedProduct } from "../../redux/features/product/productService";
import { useDispatch } from "react-redux";
import Loader from "../loader";

const ChooseProduct = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [searchText, setSearchText] = useState("");
  const [selectedProduct, setSelectedProductLocally] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, error, isLoading } = useGetProductsQuery({ search_term: searchText, page:currentPage });


  const handlePageChange = (page) => setCurrentPage(page);
  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleCardClick = (product) => setSelectedProductLocally(product);

  const handleContinue = () => {
    if (selectedProduct) {
      dispatch(setSelectedProduct(selectedProduct));
      history.push(`/${selectedProduct.id}/dashboard`);
    }
  };

  if (error) return <div>Error: {error.message}</div>;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentProducts = data?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalItems = data?.length;

  return (
    <Layout className="productContainer">
      {(isLoading) ? <Loader style= "loader" />:<Row gutter={[24, 24]} className="productRowContainer">
        <div className="productCompanyHeader">
          <img
            alt="Product Icon"
            src={wozLogo}
            width={"214px"}
            height={"88px"}
            className="mb-2"
          />
          <p className="descriptionProduct">
            Select the product dashboard you want to view
          </p>
        </div>
        <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
          <Input
            className="productSearch"
            placeholder="Search For Products"
            size="small"
            allowClear
            suffix={searchIcon}
            onChange={handleInputChange}
          />
        </Col>
        <Row
          gutter={[12, 12]}
          justify={"center"}
          align={"center"}
          style={{ width: "100%" }}
        >
          {currentProducts.map((item, index) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} key={index}>
              <Card
                bordered={false}
                cover={
                  <img
                    alt={item.name}
                    src={item.image}
                    className="productImage"
                  />
                }
                onClick={() => handleCardClick(item)}
                className="productCard"
                style={{
                  border:
                    selectedProduct === item
                      ? "2px solid #1890FF"
                      : "2px solid transparent",
                  cursor: "pointer",
                  boxShadow:
                    selectedProduct === item
                      ? "0 4px 8px rgba(130 20 217 / 16%), 0 6px 20px rgb(255 25 25 / 0%)"
                      : "none",
                }}
              >
                <Card.Meta
                  title={item.name}
                  description={
                    <>
                      <p className="product-info">{item.user}</p>
                      <p className="product-info">{item.conversation}</p>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
        <div className="chooseProductPagination">
        {totalItems > 6 && (
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
          />
        )}
        </div>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Button
            type="primary"
            onClick={handleContinue}
            className="ContinueButton mb-4"
            disabled={!selectedProduct}
          >
            Continue
          </Button>
        </Col>
      </Row>}
    </Layout>
  );
};

export default ChooseProduct;
