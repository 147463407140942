import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Button, Row, Col, Form, Input, Card, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { userForgotPassword } from "../../redux/features/forgotPassword/forgotService";
import wozMobile from "../images/WOZ.svg";
import wozImage from "../images/WOZLogo.svg";

const { Content } = Layout;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMessage: null,
      loading: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ email: e.target.value });
  };

  onFinish = async () => {
    const { email } = this.state;
    const { userForgotPassword } = this.props;
    this.setState({ loading: true, errorMessage: null });
    try {
      await userForgotPassword({ email }).unwrap();
      this.setState({ loading: false, errorMessage: null });
      this.props.history.push(`/recovery?email=${email}`);
    } catch (err) {
      this.setState({
        loading: false,
        errorMessage: err || "An error occurred",
      });
    }
  };

  onFinishFailed = () => {
    this.setState({ errorMessage: "Please check your inputs and try again." , loading: false});
  };

  render() {
    const { email, loading, errorMessage } = this.state;

    return (
      <Layout className="layout-default layout-signin">
        <Content className="signin signinHeight">
          <Row
            gutter={[24, 24]}
            justify="center"
            align="center"
            className="signinRow"
          >
            <Col className="loginSideContainer1">
              <div className="sideContainer">
                <img
                  src={wozMobile}
                  alt="login woz"
                  style={{ width: "65px", height: "37px" }}
                  className="loginWozImagemb"
                />
                <h1 className="h1">Log in to Your Account</h1>
                <p>
                  Explore how WOZ delivers personalized, interactive experiences
                  that captivate, engage and retain your customers.
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12}>
              <Card bordered={false} className="signinCard">
                <div className="sininDetailsContainer">
                  <h3 className="mb-5 h3">Forgot Password</h3>
                  <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    layout="vertical"
                    className="row-col"
                    initialValues={{ email }}
                  >
                    <Form.Item
                      className="username mb-5"
                      label="Please enter your email address"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                          backgroundColor: "#190041",
                          border: "none",
                        }}
                        block
                        disabled={loading}
                        icon={loading ? <LoadingOutlined /> : null}
                      >
                        {loading ? "Submitting..." : "Send Recovery Link"}
                      </Button>
                    </Form.Item>
                    {errorMessage && (
                      <Alert message={errorMessage} type="error" showIcon className="signin-alerts mb-4" />
                    )}
                  </Form>
                </div>
                <footer className="footer-copyright">
                    <p className="text-mute">Copyright 2024 WOZ. All Rights Reserved.</p>
                  </footer>
              </Card>
            </Col>
            <Col lg={8} xl={8} xxl={8} className="loginSideContainer">
              <div className="sideContainer">
                <img
                  src={wozImage}
                  alt="login woz"
                  style={{ width: "125px", height: "29px" }}
                  className="loginWozImage"
                />
                <h1 className="h1">Log in to Your Account</h1>
                <p>
                  Explore how WOZ delivers personalized, interactive experiences
                  that captivate, engage and retain your customers.
                </p>
              </div>
            </Col>
          <footer className="copyright mobile-copyright-footer">
            <p>© WOZ.AI 2024. All rights reserved.</p>
          </footer>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.forgot ? state.forgot.loading : false,
  errorMessage: state.forgot ? state.forgot.error : null,
});

export default connect(mapStateToProps, { userForgotPassword })(ForgotPassword);
