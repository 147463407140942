import React, { useCallback } from "react";
import { Route, Redirect } from "react-router-dom";

const validRoutes = [
  "dashboard",
  "conversation-analysis",
  "settings",
  "chat-history",
  "customer-data",
  "signin",
  "forgot-password",
  "recovery",
  "choose-product",
  "common-queries",
  "reset-password",
];

const noTokenRequiredRoutes = ["forgot-password", "recovery", "reset-password"];

const ProtectedRoute = ({
  component: Component,
  date,
  setDate,
  ...rest
}) => {
  const token = localStorage.getItem("userToken");

  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const currentPath = props.location.pathname;
        const isNoTokenRequired = noTokenRequiredRoutes.some((route) =>
          currentPath.includes(route)
        );

        if (!token && !isNoTokenRequired) {
          return <Redirect to={{ pathname: "/signin" }} />;
        }

        if (isNoTokenRequired) {
          return <Component {...props} date={date} setDate={setDate} />;
        }

        if (currentPath === "/choose-product") {
          return <Component {...props} date={date} setDate={setDate} />;
        }

        const isValidRoute = validRoutes.some((route) =>
          currentPath.includes(route)
        );

        const matchId = props.match.url;
        const productIdFromLocalStorage = getProductIdFromLocalStorage();

        if (matchId !== `/${productIdFromLocalStorage}`) {
          return <Redirect to={{ pathname: "/choose-product" }} />;
        }

        if (isValidRoute) {
          return <Component {...props} date={date} setDate={setDate} />;
        } else {
          return <Redirect to={{ pathname: "/choose-product" }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
