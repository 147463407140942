const userSettingsData = [
  {
    key: 1,
    name: "Emma Johnson",
    email: "emma.johnson@liquidwears.com",
    role: "admin",
  },
  {
    key: 2,
    name: "Liam Smith",
    email: "liam.smith@liquidwears.com",
    role: "view only",
  },
  {
    key: 3,
    name: "Olivia Brown",
    email: "olivia.brown@liquidwears.com",
    role: "accounts",
  },
  {
    key: 4,
    name: "Noah Davis",
    email: "noah.davis@liquidwears.com",
    role: "billing",
  },
  {
    key: 5,
    name: "Ava Martinez",
    email: "ava.martinez@liquidwears.com",
    role: "admin",
  },
  {
    key: 6,
    name: "William Garcia",
    email: "william.garcia@liquidwears.com",
    role: "view only",
  },
  {
    key: 7,
    name: "Sophia Wilson",
    email: "sophia.wilson@liquidwears.com",
    role: "accounts",
  },
  {
    key: 8,
    name: "James Taylor",
    email: "james.taylor@liquidwears.com",
    role: "billing",
  },
  {
    key: 9,
    name: "Isabella Anderson",
    email: "isabella.anderson@liquidwears.com",
    role: "admin",
  },
  { 
    key: 10,
    name: "Benjamin Thomas",
    email: "benjamin.thomas@liquidwears.com",
    role: "view only",
  },
];

export default userSettingsData;
