import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

export default function ChartLineGradient(props) {
  const chartRef = React.useRef(null);
  const [data, setData] = React.useState({
    labels: [],
    datasets: []
  });

  React.useEffect(() => {
    if (!chartRef.current) return; // Guard clause to prevent null reference

    const ctx2 = chartRef.current;

    // Create a gradient for the chart
    var gradientStroke1 = ctx2.ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke1.addColorStop(1, "rgba(24,144,255,0.2)");
    gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
    gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");

    // Set the chart data using the props
    setData({
      labels: props?.data?.xKeys || [], // Provide a default value in case props.data is not available
      datasets: [
        {
          label: false,
          tension: 0.4,
          pointRadius: 0,
          borderColor: "#5B8FF9",
          borderWidth: 3,
          backgroundColor: gradientStroke1,
          fill: true,
          data: props?.data?.values || [], // Provide a default value
          maxBarThickness: 6,
        }
      ],
    });
  }, [props?.data?.xKeys, props?.data?.values]); // Dependencies array to re-run the effect when props change

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#b2b9bf",
          font: {
            size: 11,
            family: "Open Sans",
            style: "normal",
            lineHeight: 2,
          },
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          color: "#b2b9bf",
          padding: 10,
          font: {
            size: 11,
            family: "Open Sans",
            style: "normal",
            lineHeight: 2,
          },
        },
      },
    },
  };

  return (
    <Chart
      ref={chartRef}
      type="line"
      data={data}
      options={options}
      height={300}
    />
  );
}
