import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export const userLogin = createAsyncThunk(
  "/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${baseURL}/login`,
        { email, password },
        config
      );
      if (!data.data.access_token) {
        return rejectWithValue("Login failed: Token not received");
      }
      localStorage.setItem("userToken", data.data.access_token);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message === "Unauthorized") {
        return rejectWithValue("Invalid Credentials");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  userInfo: null,
  userToken: localStorage.getItem('userToken') || null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('selectedProduct');
        state.loading = false;
        state.userInfo = null;
        state.userToken = null;
        state.error = null;
        state.success = false;
    },
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload.data;
        state.userToken = action.payload.data.access_token;
        state.success = true;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;
