import React, { Component } from "react";
import { Row, Button, Col } from "antd";
import { withRouter } from "react-router-dom";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const getHeaderTitle = (pathname) => {
  const partialMatchTitles = {
    "/dashboard": "Overview",
    "/conversation-analysis": "Conversation Analysis",
    "/chat-history": "Chat History",
    "/settings": "Settings",
    "/customer-data": "Customer Data",
    "/common-queries": "Common Queries",
  };

  for (const [key, value] of Object.entries(partialMatchTitles)) {
    if (pathname.includes(key)) {
      return value;
    }
  }

  return "";
};

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

class HeaderTop extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    const oneMonthAgo = moment().subtract(1, 'month');

    this.state = {
      visible: false,
      color: [],
      sidenavType: "transparent",
      collapsed: false,
      selectedProduct: JSON.parse(localStorage.getItem("selectedProduct")) || "",
      defaultDateRange: [oneMonthAgo, today], 
      displayDateRange: [oneMonthAgo, today], 
    };
  }

  componentDidMount() {
    this.props.setDate(this.state.defaultDateRange);
  }

  handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      this.setState({ displayDateRange: dates });
      this.props.setDate(dates);
    } else {
      this.setState({ displayDateRange: this.state.defaultDateRange });
      this.props.setDate(this.state.defaultDateRange);
    }
  };

  handleCalendarChange = (dates) => {
    this.setState({ displayDateRange: dates || this.state.defaultDateRange });
  };

  handleOpenChange = (open) => {
    if (!open && (!this.state.displayDateRange || this.state.displayDateRange.length === 0)) {
      this.setState({ displayDateRange: this.state.defaultDateRange });
      this.props.setDate(this.state.defaultDateRange);
    }
  };

  render() {
    const { location } = this.props;
    const headerTitle = getHeaderTitle(location.pathname);

    const renderHeader =
      location.pathname.includes("/dashboard") ||
      location.pathname.includes("/conversation-analysis")
        ? true
        : false;

    return (
      <>
        {renderHeader ? (
          <Row gutter={[24, 0]} align={'center'} justify={'center'}>
            <Col xs={18} sm={18} md={18} lg={12}>
              <div className="headerName">
                <h4 className="h4 m-0">{headerTitle}</h4>
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} lg={0} align="end">
              <Button
                type="link"
                className="sidebar-toggler p-0"
                onClick={() => this.props.onPress()}
              >
                {toggler}
              </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} className="headerColResponsive mt-3">
              <Space direction="vertical" size={12}>
                <RangePicker
                  value={this.state.displayDateRange} 
                  onChange={this.handleDateChange}
                  onCalendarChange={this.handleCalendarChange}
                  onOpenChange={this.handleOpenChange}
                  format="YYYY-MM-DD" 
                />
              </Space>
            </Col>
          </Row>
        ) : (
          <Row gutter={[24, 0]} align={'center'} justify={'center'}>
            <Col xs={12} sm={12} md={12} lg={24}>
              <div className="headerName">
                <h4 className="h4 m-0 cp-15">{headerTitle}</h4>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={0} align="end">
              <Button
                type="link"
                className="sidebar-toggler"
                onClick={() => this.props.onPress()}
              >
                {toggler}
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default withRouter(HeaderTop);
