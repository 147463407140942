import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('userToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const userToken = localStorage.getItem('userToken');
      if (!userToken) {
        localStorage.removeItem("selectedProducts")
        localStorage.removeItem("userToken")
        window.location.replace('/signin');
      }
    }
    return Promise.reject(error);
  }
);

export const userForgotPassword = createAsyncThunk(
  "/forgot-password",
  async ({ email }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/forgot-password", { email });
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("An unknown error occurred");
      }
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  successMessage: null,
};

const forgotSlice = createSlice({
  name: "forgot",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userForgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(userForgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload;
      })
      .addCase(userForgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectForgotState = (state) => state.forgot;
export default forgotSlice.reducer;
