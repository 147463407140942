import { createApi } from '@reduxjs/toolkit/query/react';
import axiosInstance from '../../axiosConfig';
import { createSlice } from '@reduxjs/toolkit';

const baseQuery = async ({ url, method, body, params }) => {
  try {
    const result = await axiosInstance({
      url,
      method,
      data: body,
      params,
    });
    return { data: result };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};


export const customerDataApi = createApi({
  reducerPath: 'customerDataApi',
  baseQuery,
  endpoints: (builder) => ({
    getCustomerData: builder.query({
      query: (productId) => {
        return {
        url: `${productId}/customer-data`,
        method: 'POST'
      }
      },
    }),
  }),
});

export const customerDataSlice = createSlice({
  name: 'customerData',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    totalRows: 0,
  },
  reducers: {
    setCustomerData: (state, action) => {
      state.data = action.payload.data;
      state.totalRows = action.payload.total;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { useGetCustomerDataQuery } = customerDataApi;
export const { setCustomerData, setLoading, setError } = customerDataSlice.actions;
export default customerDataSlice.reducer;

window.addEventListener('storage', (event) => {
  if (event.key === 'selectedProduct') {
    customerDataApi.endpoints.getCustomerData.initiate(); 
  }
});