import React, { useState, useEffect, useCallback } from "react";
import { Card, Col, Row, Typography } from "antd";
import WordCloud from "react-d3-cloud";
import Echart from "../chart/Echart";
import ChartLineGradient from "../pages/chart/ChartLineGradient";
import { useGetConversationalDataQuery } from "../../redux/features/conversational-analysis/conversationalService";
import Loader from "../loader";

const Analysis = ({date}) => {
  const [AnalysisData, setAnaylsisData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [sentimentGraph, setSentimentGraph] = useState([]);
  const [SentimentButton, setSentimentButton] = useState([]);
  const [keywordCloud, setKeyWordCloud] = useState([]);
  
  const buttonsSvg = {
    "Positive": <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M21.6509 10.03C21.2609 9.46997 20.5709 9.14997 19.7809 9.14997H15.6809C15.4109 9.14997 15.1609 9.03998 14.9909 8.83998C14.8109 8.63998 14.7409 8.35997 14.7809 8.06997L15.2909 4.78997C15.5109 3.80997 14.8609 2.70998 13.8809 2.37998C12.9709 2.03998 11.9009 2.49998 11.4709 3.14998L7.25086 9.41998L7.13086 9.61998V18.46L7.28086 18.61L10.4509 21.06C10.8709 21.48 11.8209 21.71 12.4909 21.71H16.3909C17.7309 21.71 19.0809 20.7 19.3809 19.47L21.8409 11.98C22.1009 11.27 22.0309 10.58 21.6509 10.03Z" fill="#fff" />
      <path d="M5.21 6.37988H4.18C2.63 6.37988 2 6.97988 2 8.45988V18.5199C2 19.9999 2.63 20.5999 4.18 20.5999H5.21C6.76 20.5999 7.39 19.9999 7.39 18.5199V8.45988C7.39 6.97988 6.76 6.37988 5.21 6.37988Z" fill="#fff" />
    </svg>,
    "Neutral": <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#fff" />
      <path d="M15.9199 12.75H7.91992C7.50992 12.75 7.16992 12.41 7.16992 12C7.16992 11.59 7.50992 11.25 7.91992 11.25H15.9199C16.3299 11.25 16.6699 11.59 16.6699 12C16.6699 12.41 16.3399 12.75 15.9199 12.75Z" fill="#fff" />
    </svg>,
    "Negative": <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M2.34904 13.96C2.73904 14.52 3.42904 14.84 4.21904 14.84H8.31904C8.58904 14.84 8.83904 14.95 9.00904 15.15C9.18904 15.35 9.25904 15.63 9.21904 15.92L8.70904 19.2C8.48904 20.18 9.13904 21.28 10.119 21.61C11.029 21.95 12.099 21.49 12.529 20.84L16.739 14.57L16.859 14.37V5.53003L16.709 5.38003L13.539 2.93003C13.119 2.51003 12.169 2.28003 11.499 2.28003H7.59904C6.25904 2.28003 4.90904 3.29003 4.60904 4.52003L2.14904 12.01C1.89904 12.72 1.96904 13.42 2.34904 13.96Z" fill="#fff" />
      <path d="M18.7894 17.6099H19.8194C21.3694 17.6099 21.9994 17.0099 21.9994 15.5299V5.4799C21.9994 3.9999 21.3694 3.3999 19.8194 3.3999H18.7894C17.2394 3.3999 16.6094 3.9999 16.6094 5.4799V15.5399C16.6094 17.0099 17.2394 17.6099 18.7894 17.6099Z" fill="#fff" />
    </svg>,
  }
  const buttonsColors = {
    "Positive": "#00AB55",
    "Negative": "#FF4560",
    "Neutral": "#FEB019",
  }
  function sentimentButton(sentimentOverview) {
    const sentimentCopy = sentimentOverview.map(item => ({ ...item }));
    return sentimentCopy
      .map((item) => {
        const { type, title } = item;
        const svg = buttonsSvg[type];
        const color = buttonsColors[type];

        if (svg && color) {
          return {
            title,
            image: svg,
            color,
          };
        } else {
          console.warn(`Type "${type}" is not defined in buttonsSvg or buttonsColors.`);
          return null;
        }
      })
      .filter((button) => button !== null);
  }
  
  function addColorParameter(arr) {
    return arr.map(item => ({
      ...item,
      color: buttonsColors[item.name]
    }));
  }

  const makeMultipleOf100 = (arr) => {
    return arr.map(item => ({
      ...item,
      value: item.value
    }));
  };

  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);

  const productId = getProductIdFromLocalStorage();
  const { data, refetch: refetchOverviewData } = useGetConversationalDataQuery({
    productId: productId,
    bodyParams: { start_date: date[0].format("YYYY-MM-DD"), end_date: date[1].format("YYYY-MM-DD") }
  });

  useEffect(() => {
    if (data) {
      setAnaylsisData(data);
      setCustomerData({
        tiles: [
          {
            today: "Customer Satisfaction Score (CSAT)",
            title: `${data?.csat}`,
            icon: <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.15 15.3709C12.93 15.0509 12.54 14.8709 12.09 14.8709H9.76C9.61 14.8709 9.46 14.8109 9.37 14.6909C9.27 14.5709 9.23 14.4209 9.25 14.2509L9.54 12.3909C9.66 11.8409 9.29 11.2109 8.74 11.0209C8.22 10.8309 7.62 11.0909 7.37 11.4609L5.06 14.9009V14.4709C5.06 13.6309 4.7 13.2909 3.82 13.2909H3.24C2.36 13.3009 2 13.6409 2 14.4809V20.1909C2 21.0309 2.36 21.3709 3.24 21.3709H3.82C4.66 21.3709 5.01 21.0409 5.04 20.2809L6.79 21.6309C7.04 21.8709 7.57 22.0009 7.95 22.0009H10.16C10.92 22.0009 11.68 21.4309 11.86 20.7309L13.26 16.4809C13.41 16.0709 13.37 15.6809 13.15 15.3709Z"
                fill="#fff"
              />
              <path
                d="M21.1108 2.61047H20.5308C19.6908 2.61047 19.3408 2.94047 19.3008 3.70047L17.5508 2.35047C17.3108 2.11047 16.7708 1.98047 16.3908 1.98047H14.1808C13.4208 1.98047 12.6608 2.55047 12.4808 3.25047L11.0808 7.50047C10.9308 7.91047 10.9808 8.30047 11.1908 8.61047C11.4108 8.93047 11.8008 9.11047 12.2508 9.11047H14.5808C14.7308 9.11047 14.8808 9.17047 14.9708 9.29047C15.0708 9.41047 15.1108 9.56047 15.0908 9.73047L14.8008 11.5905C14.6808 12.1405 15.0508 12.7705 15.6008 12.9605C16.1208 13.1505 16.7208 12.8905 16.9708 12.5205L19.2808 9.08047V9.51047C19.2808 10.3505 19.6408 10.6905 20.5208 10.6905H21.1008C21.9808 10.6905 22.3408 10.3505 22.3408 9.51047V3.78047C22.3508 2.95047 21.9908 2.61047 21.1108 2.61047Z"
                fill="#fff"
              />
            </svg>
          },
          {
            today: "Drop-off Rate",
            title: `${data?.drop_off_rate.value} %`,
            persent: `${data?.drop_off_rate.change_percent} %`,
            bnb: "bnb2",
            icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z" fill="white" />
              <path d="M4.55994 11.25L6.62994 9.17997C6.77994 9.02997 6.84994 8.83997 6.84994 8.64997C6.84994 8.45997 6.77994 8.25997 6.62994 8.11997C6.33994 7.82997 5.85994 7.82997 5.56994 8.11997L2.21994 11.47C1.92994 11.76 1.92994 12.24 2.21994 12.53L5.56994 15.88C5.85994 16.17 6.33994 16.17 6.62994 15.88C6.91994 15.59 6.91994 15.11 6.62994 14.82L4.55994 12.75H8.99994V11.25H4.55994Z" fill="white" />
            </svg>
          },
          {
            today: "Total Inquiries Handled",
            title: `${data?.total_enquiries}`,
            icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 2H7C4.24 2 2 4.23 2 6.98V12.96V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V6.98C22 4.23 19.76 2 17 2ZM15.66 14.53C15.51 14.68 15.32 14.75 15.13 14.75C14.94 14.75 14.75 14.68 14.6 14.53L13.86 13.79C13.28 14.17 12.58 14.4 11.83 14.4C9.79 14.4 8.13 12.74 8.13 10.7C8.13 8.66 9.78 7 11.83 7C13.88 7 15.53 8.66 15.53 10.7C15.53 11.45 15.3 12.15 14.92 12.73L15.66 13.47C15.95 13.76 15.95 14.24 15.66 14.53Z" fill="white"/>
            </svg>
          },
          {
            today: "Average Conversational Duration",
            title: `${data?.avg_conversation_duration?.value}`,
            icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 13.5C4.06 13.5 3.19 13.83 2.5 14.38C1.58 15.11 1 16.24 1 17.5C1 19.71 2.79 21.5 5 21.5C6.01 21.5 6.93 21.12 7.64 20.5C8.47 19.77 9 18.7 9 17.5C9 15.29 7.21 13.5 5 13.5ZM6 17.75C6 18.01 5.86 18.26 5.64 18.39L4.39 19.14C4.27 19.22 4.13 19.25 4 19.25C3.75 19.25 3.5 19.12 3.36 18.89C3.15 18.53 3.26 18.07 3.62 17.86L4.51 17.33V16.25C4.51 15.84 4.85 15.5 5.26 15.5C5.67 15.5 6 15.84 6 16.25V17.75Z" fill="white"/>
            <path d="M17.25 2.42969H7.75C4.9 2.42969 3 4.32969 3 7.17969V11.6397C3 11.9897 3.36 12.2397 3.7 12.1497C4.12 12.0497 4.55 11.9997 5 11.9997C7.86 11.9997 10.22 14.3197 10.48 17.1297C10.5 17.4097 10.73 17.6297 11 17.6297H11.55L15.78 20.4497C16.4 20.8697 17.25 20.4097 17.25 19.6497V17.6297C18.67 17.6297 19.86 17.1497 20.69 16.3297C21.52 15.4897 22 14.2997 22 12.8797V7.17969C22 4.32969 20.1 2.42969 17.25 2.42969ZM15.83 10.8097H9.17C8.78 10.8097 8.46 10.4897 8.46 10.0997C8.46 9.69969 8.78 9.37969 9.17 9.37969H15.83C16.22 9.37969 16.54 9.69969 16.54 10.0997C16.54 10.4897 16.22 10.8097 15.83 10.8097Z" fill="white"/>
            </svg>            
          },
        ],
        sentiment: {
          heading: "Sentiment Overview",
          desc: (
            <>
              {`${data?.sentiment_overview_paragraph}`}
            </>
          ),

          subtitle: "Sentiment Score",
          percent: `${data?.sentiment_score}`,
        },
      });

      const originalData = addColorParameter(data?.sentiment_split_by_topic?.topicSeries)
      const reorderedData = originalData.sort((a, b) => {
        const order = ["Positive", "Neutral", "Negative"];
        return order.indexOf(a.name) - order.indexOf(b.name);
      });
      setSentimentGraph(reorderedData);
      setSentimentButton(sentimentButton(data?.sentiment_overview));
      const transformedData = makeMultipleOf100(data?.keyword_cloud);
      setKeyWordCloud(transformedData);
    }
  }, [data]);

  const calculateOpacity = (value, maxVal) => {
    const percentage = Math.log10(value + 1) / Math.log10(maxVal + 1); 
    const opacity = Math.round(percentage * 255)
      .toString(16)
      .padStart(2, "0");
    return opacity;
};

const fontFix = (word) => {
    let valueArray = keywordCloud?.map((item) => Number(item?.value));
    const minFontSize = 30;
    const maxFontSize = 128;
    let logMax = Math.log10(Math.max(...valueArray) + 1);
    let logMin = Math.log10(Math.min(...valueArray) + 1);
    let logValue = Math.log10(word?.value + 1);
    if(Math.max(...valueArray)=== Math.min(...valueArray)){
      logMax=90;
      logMin = 20;
      logValue = 30;
    }
    const fontSize = minFontSize + (maxFontSize - minFontSize) * ((logValue - logMin) / (logMax - logMin));
    return fontSize;
};

const colorMapping = {
    Positive: "#00AB55",
    Negative: "#CC0000",
};

function randomColor(type) {
    return colorMapping[type] || '#ffffff';
}

const fill = (word) => {
    const maxVal = Math.max(...keywordCloud.map((d) => d.value));
    const baseColor = randomColor(word.type);
    const opacity = calculateOpacity(word.value, maxVal);
    const colorWithOpacity = `${baseColor}${opacity}`;
    return colorWithOpacity;
};

  const { Title } = Typography;
  const { tiles, sentiment } = customerData || {};

  return (
    <>
      {(AnalysisData) ? (
        <div className="layout-content">
          <section className="App conversationSection analysis">
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Row gutter={[24, 0]}>
                  {tiles.map((count, index) => (
                    <Col
                      key={index}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={6}
                      xxl={6}
                      className="mb-24"
                    >
                      <Card
                        bordered={false}
                        className="crm-bar-line header-solid dashIconStyles"
                      >
                        <div className="dashTile dashTileFlex">
                          <div>
                            <h6 className="text-md font-semibold text-muted">
                              {count.today}
                            </h6>
                            <div className="numbersFlex">
                              <h2 className="h3">{count.title}</h2>
                              <small className="text-succes">
                                {count.persent}
                              </small>
                            </div>
                          </div>
                          <div className="icon-box icon-box-analysis">{count.icon}</div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={18} className=" mb-24">
                <Card bordered={false} className="criclebox card-info-2 h-full">
                  <Title level={4}>Sentiment Split By Topic</Title>
                  <Echart title="topic" series={sentimentGraph} labels={AnalysisData?.sentiment_split_by_topic?.labels} />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={6} className="mb-24">
                <Card bordered={false} className="criclebox card-info-2 h-full">
                  <div className="h-full col-content">
                    <div className="card-content mb-4">
                      <Title level={4} className="mb-4">{sentiment.heading}</Title>
                      <p>{sentiment.desc}</p>
                    </div>
                    <div className="sentimentCard mb-4">
                      {sentimentButton && SentimentButton.map((item, index) => (
                        <div
                          key={index}
                          style={{ backgroundColor: item.color }}
                          className="buttonsContainer"
                        >
                          {item.image}
                          <Title level={5} className="buttonText">
                            {item.title}
                          </Title>
                        </div>
                      ))}
                    </div>
                    <div className="card-content">
                      <Title level={5} className="sentimentSubtitle mb-4">{sentiment.subtitle}</Title>
                      <p className="subtitleSentiment">{sentiment?.percent}%</p>
                      <div className="sentimentProgress">
                        <div
                          style={sentiment?.percent && { width: `${sentiment?.percent}%` }}
                          className="sentimentProgress sentimentProgress1"
                        >
                          <span className="sentimentDynamicEnd" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
                className="mb-5"
              >
                <Card
                  bordered={false}
                  className="header-solid mb-24 h-full"
                  title={<Title level={4}>Sentiment Trend Overtime</Title>}
                >
                  <ChartLineGradient data={AnalysisData?.sentiment_trend_overtime} />
                </Card>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
                className="mb-5"
              >
                <Card
                  bordered={false}
                  className="criclebox card-info-2 h-full wordcloud"
                >
                  <Title level={4}>Keyword Cloud</Title>
                  <div className="wordCloudCenter">
                    <WordCloud
                      data={keywordCloud && keywordCloud}
                      fontWeight="bold"
                      height={400}
                      width={800}
                      rotate={0}
                      fontSize={(word) => word && fontFix(word)}
                      fill={(word) => fill(word)}
                      spiral="rectangular"
                      padding={5}
                      random={Math.random}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </section>
        </div>
      ) : <Loader />
      }
    </>
  );
};

export default Analysis;
